import React from 'react'
import { Link } from 'react-router-dom'
import styles from './homeServices.module.css'

export default function HomeServices() {
  return (
    <div className={styles['services-div']}>
        <Link to='/history'><img className={styles['service-img']} src='/merg.jpg' alt='Megrelyan' /></Link>
        <Link to='/expo'><img className={styles['service-img']} src='/Expo.jpg' alt='expo' /></Link>
        <Link to='https://meet.mergelyan.com'><img className={styles['service-img']} style={{marginTop: 0}} src='/Meet.jpg' alt='meet' /></Link>
    </div>
  )
}
