import React from 'react'
import HomeServices from '../components/HomeServices'
import ImagesSlide from '../components/ImagesSlide'
import styles from './home.module.css'


export default function Home({children}) {
  return (
    <div>
      <div className={styles['home-div']}>
        <HomeServices />
        {children}
      </div>
      <ImagesSlide />
    </div>
    
  )
}
