export const menu = [
    {
        name: {
            arm : 'Մեր մասին',
            ru: "О нас",
            eng: "About us",
        },
        category: 'about',
        underCategory: [
            { 
                name: {
                    arm : 'Պատմություն',
                    ru : "История",
                    eng : "History"
                },
                category: 'history',
                underCategory: null
            },
            { 
                name: {
                    arm : 'Ղեկավարներ',
                    ru: "Лидеры",
                    eng: "Leaders"
                },
                category: 'leaders',
                underCategory: null
            },
            { 
                name: {
                    arm : 'Գործընկերներ',
                    ru: "Партнеры",
                    eng: "Partners"
                },
                category: 'partners',
                underCategory: null
            },
            { 
                name: {
                    arm: 'Տեսադարան',
                    ru: "Галерея",
                    eng: "Gallery",
                },
                category: 'library',
                underCategory: null
            }
        ]
    },
    {
        name: {
            arm:'Գործունեություն',
            ru: "Деятельность",
            eng: "Activity",
        },
        category: 'activity',
        underCategory: [
            { 
                name: {
                    arm: 'Արտադրանք',
                    ru: "Продукт",
                    eng: "Products",
                },
                category: 'products',
                underCategory: null
            },
            { 
                name: {
                    arm: 'Վարձակալություն',
                    ru: "Аренда",
                    eng: "Lease",
                },
                category: 'rent',
                underCategory: null
            },
            { 
                name: {
                    arm: 'Թանգարան',
                    ru: "Музей",
                    eng: "Museum",
                },
                category: 'museum',
                underCategory: null
            }, 
            { 
                name: {
                    arm:'Երևան Էքսպո',
                    ru: "Ереван Экспо",
                    eng: "Yerevan Expo",
                },
                category: 'expo',
                underCategory: null
            }
        ]
    },
    {
        name: {
            arm:'Տեղեկատվական կենտրոն',
            ru: "Информационный центр",
            eng: "Information center",
        },
        category: 'info-centre',
        underCategory: [
            { 
                name: {
                    arm:'Նորություններ',
                    ru: "Новости",
                    eng: "News",
                },
                category: 'news',
                underCategory: null
            },
            { 
                name: {
                    arm:'Հայտարարություններ',
                    ru: "Объявления",
                    eng: "Announcements",
                },
                category: 'declaration',
                underCategory: null
            },
            { 
                name: {
                    arm:'Աշխատանք',
                    ru: "Вакансии",
                    eng: "Vacancies",
                },
                category: 'career',
                underCategory: null
            }
        ]
    },
    {
        name: {
            arm:'Հետադարձ կապ',
            ru: "Связаться с нами",
            eng: "Contact us",
        },
        category: 'contacts',
        underCategory: null
    },
]
