import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styles from './menu.module.css'
import { store } from '../HOC/State'

export default function MenuItem({el, isOpen, setIsOpen}) {
    const {setShowMenu, setSelectedCategory, selectedLang} = useContext(store)

    function setCategory(){
        if(isOpen === el.category){
            setIsOpen(null)
        }else{
            setIsOpen(el.category)
        }
    }

    
    if(!el.underCategory){
        return (
        <Link to={el.category} onClick={() => {setShowMenu(false); setIsOpen(null); setSelectedCategory(el.category)}}>
            <div className={isOpen === el.category ? `${styles['menu-full-item-div']} ${styles['active']}` : styles['menu-full-item-div'] }>
                <div className={styles['menu-item-name-div']}>
                    <p>
                        {el.name[selectedLang]}
                    </p>
                </div>
            </div>
        </Link>
        )
    }else{
        return (
            <div>
                <div onClick={setCategory} className={isOpen === el.category ? `${styles['menu-full-item-div']} ${styles['active']}` : styles['menu-full-item-div'] }>
                    <div className={styles['menu-item-name-div']}>
                        <p>{el.name[selectedLang]}</p>
                        <p className={styles['opened']}>{isOpen === el.category ? "-" : "+"}</p>
                    </div>
                </div>
                <div>
                    {isOpen === el.category ? el.underCategory.map((el,i) =><div key={el.category} className={styles['underCategory-items']}><MenuItem  el={el} isOpen={isOpen} setIsOpen={setIsOpen}/></div>) : ""}
                </div>
            </div>
        )
    }
}
